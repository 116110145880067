// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    common: {
      companyName: 'CTL Cargo',
      slogan: "Su solución logística global",
      aboutUs1: "CTL es una empresa de logística joven pero con experiencia, enfocada en la iniciativa y la experiencia del cliente. Entendemos que todos y cada uno de nuestros clientes tienen sus propios requisitos y nuestro objetivo es que, al final del día, hagamos que el proceso logístico de nuestros clientes sea más fácil y eficiente.",
      aboutUs2: "Como Agente de Carga, nuestro foco es ser tu compañero, una extensión para cada uno de nuestros clientes. Podemos ofrecer todas las opciones de transporte internacional desde cualquier parte del mundo como Consultor Logístico, para esto nos apoyamos en diferentes redes internacionales de agentes de carga, que nos brindan cobertura en todo el mundo.",
      whatCanWeDoForYou: "¿Qué podemos hacer por ti?",
      whatCanWeDoForYouDescription: "Como Freight Forwarder, nuestro enfoque es ser tu socio, una extensión de cada uno de nuestros clientes. Para ello, nos hemos unido a WWPC World Network, una comunidad de agentes repartidos por todo el mundo, con el objetivo de poder prestar todos los servicios logísticos a nivel global.",
      providingLogisticsSolutions: "Aportando Soluciones Logísticas",
      contacts: "Contactos",
      home: "Inicio",
      aboutUs: "Sobre nosotros",
      services: "Servicios",
      contactUs: "Contacto",
      getInTouch: 'Ponerse en contacto',
      getInTouchDescription: 'Complete el siguiente formulario para enviarnos un correo electrónico y nos pondremos en contacto con usted lo antes posible.',
      contactInfo: 'Datos de contacto',
      address: 'Dirección',
      dominicanRepublic: 'República Dominicana',
      panama: 'Panamá',
      phone: 'Teléfono',
      name: 'Nombre',
      email: 'Correo electrónico',
      message: 'Mensaje',
      warehousingDistribuiton: 'Almacenamiento y Distribución',
      airImports: 'Importaciones Aéreas',
      airExport: 'Exportaciones Aéreas',
      FCLImports: 'Importaciones Marítimas',
      FCLExports: 'Exportaciones Marítimas',
      IORImporterOrRecord: 'Importador de Récord',
      customBrokerage: 'Despacho Aduanal ',
      inland: 'Transporte Terrestre',
      importExportconsolidation: 'Consolidación de Exportación e Importación',
      thanksForContactingUs: 'Gracias por contactarnos. Nos pondremos en contacto con usted lo antes posible.',
    },
    action: {
      sendMessage: 'Enviar mensaje'
    }
  }
}
